import React, {useEffect} from 'react';
import {Route, useHistory} from 'react-router-dom';
import Settings from "../../Settings/Settings";
import SideMenu from "../../../layout/SideMenu/SideMenu";
import menuData from './menuData';
import CompaniesList from "../../Companies/CompaniesList/CompaniesList";
import SingleCompany from "../../Companies/SingleCompany/SingleCompany";
import CreateCompany from "../../Companies/CreateCompany/CreateCompany";
import UpdateCompany from "../../Companies/UpdateCompany/UpdateCompany";
import NewRecord from "../../Dashboard/NewReport/NewReport";
import WeeklyReports from "../../Dashboard/WeeklyReports";
import {connect, useDispatch, useSelector} from 'react-redux';
import {fetchNotifications} from '../../../store/notifications/user/userNotifications.actions';
import Notifications from "../../Notifications/Notifications";
import Offers from "../../Dashboard/Offers";
import {NewReminder} from "../../Dashboard/NewReminder";
import {Reminders} from "../../Reminders";
import {getReminders} from "../../../store/reminders/reminders.actions";
import {Competitors} from "../../Competitors";


const Sales = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {reminders} = useSelector((state) => state.reminders);

  useEffect(() => {
    if (reminders.data) {
      history.push('/reminders')
    }
  }, [reminders.data]);

  useEffect(() => {
    props.fetchNotifications()
    dispatch(getReminders());
  }, []);

  return (
    <React.Fragment>
      <NewReminder/>
      <SideMenu menu={menuData}/>
      <Route exact path='/' component={WeeklyReports}/>
      <Route exact path='/reminders' component={Reminders}/>
      <Route path='/settings' component={Settings}/>
      <Route exact path='/companies/list' component={CompaniesList}/>
      <Route path='/company/:id' component={SingleCompany}/>
      <Route exact path='/companies/create' component={CreateCompany}/>
      <Route exact path='/update-company/:id' component={UpdateCompany}/>
      <Route exact path='/dashboard/new-report' component={NewRecord}/>
      <Route exact path='/dashboard/reports/week' component={WeeklyReports}/>
      <Route exact path='/notifications' component={Notifications}/>
      <Route exact path='/dashboard/offers' component={Offers}/>
      <Route exact path='/competitors' component={Competitors}/>
    </React.Fragment>
  );
};

export default connect(null, {fetchNotifications})(Sales);
