import * as types from '../types';

const initialState = {
  competitors: {
    data: [],
    isLoading: false,
  },
  current: {
    notes: [],
    isLoading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Competitors
    case types.COMPETITORS_GET:
      return {...state, competitors: {data: [], isLoading: true}};
    case types.COMPETITORS_GET_DONE:
      return {...state, competitors: {data: action.data, isLoading: false}};
    case types.COMPETITOR_ADD_DONE:
      return {
        ...state,
        competitors: {data: [...state.competitors.data, action.payload], isLoading: false},
      };
    case types.COMPETITORS_OPEN:
      return {...state, current: {notes: [], files: [], isLoading: false}};
    case types.COMPETITOR_DELETE_DONE:
      return {
        ...state,
        competitors: {
          data: state.competitors.data.filter((c) => c.id !== action.payload.id),
          isLoading: false,
        },
      };

    // Notes
    case types.NOTES_GET:
      return {...state, current: {...state.current, notes: [], isLoading: true}};
    case types.NOTES_GET_DONE:
      return {...state, current: {...state.current, notes: action.data, isLoading: false}};
    case types.NOTE_ADD_DONE:
      return {
        ...state,
        current: {...state.current, notes: [action.payload, ...state.current.notes]},
      };
    case types.NOTE_DELETE_DONE:
      return {
        ...state,
        current: {
          ...state.current,
          notes: state.current.notes.filter((note) => note._id !== action.payload.noteId),
        },
      };
    default:
      return state;
  }
};
