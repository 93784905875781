import React, {useEffect, useState} from 'react';
import style from "./index.module.scss";
import Icon from "../../../../components/ui/Icon/Icon";
import Select from "../../../../components/ui/Select/Select";
import {connect} from 'react-redux';
import * as actions from "../../../../store/dashboard/offers/offers.actions";
import {fetchCategories} from "../../../../store/companies/categories/categories.actions";
import {fetchRegions} from "../../../../store/companies/regions/regions.actions";
import {fetchUsers} from "../../../../store/users/users.actions";
import InputPrimary from "../../../../components/ui/InputPrimary/InputPrimary";
import {useDebounce} from "../../../../utils/useDebounce";

const SearchBar = (props) => {

  const {fields} = props.offers;

  const [tempValue, setTempValue] = useState(fields.company);

  useEffect(() => {
    setTempValue(fields.company)
  }, [fields.company]);

  const onChange = () => {
    props.onSearchInputChange({target: {name: 'company', value: tempValue}})
  }

  useDebounce(tempValue, onChange)


  useEffect(() => {
    if (!props.regions || !props.categories) {
      props.fetchUsers()
    }
    // eslint-disable-next-line
  }, [fields]);

  const clearFilter = () => {
    props.clearFilter();
  };

  // users list for selection
  const users = props.users && props.users.map((user) => {
    const {_id, firstName, lastName} = user;
    return {value: {_id, firstName, lastName}, label: `${firstName} ${lastName}`}
  });

  const reportStatus = [
    {label: 'Aktyvus', value: 'active'},
    {label: 'Laimėtas', value: 'sold'},
    {label: 'Atšauktas', value: 'canceled'},
  ];

  return (
    <div className={style.searchBar}>
      <div className={style.basic}>
        <Icon
          icon='times'
          onClick={clearFilter}
          className={style.clearFilterBtn}
        />
        {props.user.role === 'admin' &&
          <Select
            name='user'
            onChange={props.onSearchInputChange}
            className={style.select}
            value={fields.user}
            placeholder='Priskirtas asmuo'
            type='primary-light'
            capitalize
            options={users || []}
          />
        }
        <input name='company' placeholder='Įmonė' value={tempValue} className={style.input} type='text'
               onChange={(e) => setTempValue(e.target.value)}/>
        <Select
          name='state'
          onChange={props.onSearchInputChange}
          className={style.select}
          value={fields.state}
          placeholder='Būsena'
          type='primary-light'
          capitalize
          options={reportStatus}/>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    offers: state.dashboard.offers,
    regions: state.companies.regions,
    categories: state.companies.categories,
    users: state.users.usersList,
    user: state.auth.user
  }
};
export default connect(mapStateToProps, {
  ...actions,
  fetchCategories,
  fetchRegions,
  fetchUsers
})(SearchBar);
