import React, {useState} from 'react';
import Modal from "../../components/ui/Modal/Modal";
import Heading2 from "../../components/ui/Heading2/Heading2";
import TextArea from "../../components/ui/TextArea/TextArea";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from "../../components/ui/Button/Button";
import Dropzone from "react-dropzone";
import Icon from "../../components/ui/Icon/Icon";
import {useDispatch} from "react-redux";
import {addNote} from "../../store/competitors/competitors.actions";

const cx = classNames.bind(styles);

export const NewNote = (props) => {
  const {showNewCompetitorModal, competitor, isVisible} = props;
  const [inputValue, setInputValue] = useState('');
  const [files, updateFiles] = useState([]);
  const dispatch = useDispatch();

  const onAddFile = (acceptedFiles) => {
    updateFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const onRemoveFile = (index) => {
    updateFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onSaveNote = () => {
    if (!inputValue.trim()) {
      return;
    }

    const noteData = {
      text: inputValue,
      files
    };

    dispatch(addNote(competitor.id, noteData)); // Dispatch the action to add a note
    showNewCompetitorModal(false); // Close the modal after saving

    setInputValue('');
    updateFiles([])
  };

  return (
    <div className={cx('new-note')}>
      <Modal visible={isVisible} hideModal={() => showNewCompetitorModal(false)} className={cx('new')}>
        <Heading2>{competitor?.name || ''}</Heading2>
        <TextArea
          name=''
          label='Aprašymas'
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          className={cx('text-area')}
        />
        <div className={cx('file-upload')}>
          <h5 className={cx('label')}>Prisegti dokumentai</h5>
          <Dropzone onDrop={onAddFile}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className={cx('files')}>
                <input {...getInputProps()} />
                {files.map((file, i) => (
                  <p key={i} onClick={(e) => e.stopPropagation()}>
                    <span>{file.name}</span>
                    <Icon
                      icon='trash'
                      className={cx('trash')}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveFile(i);
                      }}
                    />
                  </p>
                ))}
                <strong>Įkelti dokumentus</strong>
              </div>
            )}
          </Dropzone>
        </div>
        <div className={cx('buttons')}>
          <Button type='primary' onClick={() => showNewCompetitorModal(false)} className={cx('cancel')}>
            Atšaukti
          </Button>
          <Button type='primary' onClick={onSaveNote}>
            Išsaugoti
          </Button>
        </div>
      </Modal>
    </div>
  );
};
