import * as types from '../types';
import axios from "axios";
import {showError} from "../utils";

// Competitor Actions
export const getCompetitors = () => {
  return async function (dispatch) {
    dispatch({type: types.COMPETITORS_GET});
    try {
      const {data} = await axios.get('/api/competitors');
      dispatch({type: types.COMPETITORS_GET_DONE, data});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const addCompetitor = (item) => {
  return async function (dispatch) {
    dispatch({type: types.COMPETITOR_ADD});
    try {
      const {data} = await axios.post('/api/competitors', item);
      dispatch({type: types.COMPETITOR_ADD_DONE, payload: data});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const deleteCompetitor = (id) => {
  return async function (dispatch) {
    dispatch({type: types.COMPETITOR_DELETE});
    try {
      await axios.delete(`/api/competitors/${id}`);
      dispatch({type: types.COMPETITOR_DELETE_DONE, payload: {id}});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

// Note Actions
export const getNotes = (competitorId) => {
  return async function (dispatch) {
    dispatch({type: types.NOTES_GET});
    try {
      const {data} = await axios.get(`/api/notes/${competitorId}`);
      dispatch({type: types.NOTES_GET_DONE, data});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const addNote = (competitorId, note) => {
  return async function (dispatch) {
    dispatch({ type: types.NOTE_ADD });
    try {
      const { data } = await axios.post(`/api/notes/${competitorId}`, note);
      let files = [];
      if (note.files.length) {
        const formData = new FormData();
        formData.append('_id', data._id);
        note.files.forEach(file => formData.append('files', file));

        const res = await axios.post('/api/notes/upload', formData);
        files = [...files, ...res.data]; // Merge the uploaded files into the `files` array
      }
      dispatch({ type: types.NOTE_ADD_DONE, payload: { ...data, files } });
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const deleteNote = (competitorId, noteId) => {
  return async function (dispatch) {
    dispatch({type: types.NOTE_DELETE});
    try {
      await axios.delete(`/api/notes/${competitorId}/${noteId}`);
      dispatch({type: types.NOTE_DELETE_DONE, payload: {noteId}});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const updateNote = (noteId, updatedData) => {
  return async function (dispatch) {
    dispatch({type: types.NOTE_UPDATE});
    try {
      const {data} = await axios.put(`/api/notes/${noteId}`, updatedData);
      dispatch({type: types.NOTE_UPDATE_DONE, payload: data});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const uploadNoteFiles = (noteId, files) => {
  return async function (dispatch) {
    dispatch({type: types.NOTE_FILES_UPLOAD});
    try {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file));
      const {data} = await axios.post(`/api/notes/${noteId}/files`, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      });
      dispatch({type: types.NOTE_FILES_UPLOAD_DONE, payload: data});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};

export const deleteNoteFile = (noteId, fileName) => {
  return async function (dispatch) {
    dispatch({type: types.NOTE_FILE_DELETE});
    try {
      await axios.delete(`/api/notes/${noteId}/files/${fileName}`);
      dispatch({type: types.NOTE_FILE_DELETE_DONE, payload: {fileName}});
    } catch (e) {
      showError(dispatch, e);
    }
  };
};
