import React, {useEffect} from 'react';
import Loader from "../../components/ui/Loader/Loader";
import Table from "../../components/ui/Table/Table";
import Button from "../../components/ui/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Heading2 from "../../components/ui/Heading2/Heading2";
import {deleteCompetitor, deleteNote, getNotes} from "../../store/competitors/competitors.actions";
import moment from "moment";
import Icon from "../../components/ui/Icon/Icon";
import style from "../../components/ReportCard/ReportFiles/index.module.scss";
import {useRole} from "../../utils/useRole";
import {useIsOwner} from "../../utils/useIsOwner";

const cx = classNames.bind(styles);

export const CompetitorData = ({competitor, showNewCompetitorModal, setSelectedItem}) => {
  const {id, name} = competitor;
  const dispatch = useDispatch();
  const {current} = useSelector(state => state.competitors);
  const {notes, isLoading} = current;
  const {isAdmin} = useRole();
  const userId = useSelector((state)=>state.auth.user._id);

  useEffect(() => {
    dispatch(getNotes(id)); // Fetch notes when component mounts
  }, [id, dispatch]);

  const onDelete = () => {
    dispatch(deleteCompetitor(id))
    setSelectedItem(undefined)
  }

  const renderNotes = () => {
    if (!notes) return <Loader loading={true}/>
    if (notes.length === 0) return <p className={cx('no-records')}>Jokių įrašų nerasta.</p>
    return notes.map((note, index) => (
      <Table key={note._id} className={cx('note')}>
        <p className={cx('date')}>{moment(note.createdAt).format('YYYY-MM-DD')}</p>
        <p className={cx('author')}>{`${note.createdBy.firstName} ${note.createdBy.lastName}`}</p>
        <p className={cx('text')}>{note.text}</p>
        {(isAdmin || userId ===note.createdBy._id) && <Icon onClick={() => dispatch(deleteNote(id, note._id))} icon='trash' className={cx('icon-trash')}/>}
        {note.files.length > 0 && (
          <div>
            {note.files.map((file) => (
              <React.Fragment key={file}>
                <div className={cx('file')}>
                  <a href={`/competitor-notes/${file}`} download>
                    {file}
                  </a>
                </div>
                <div/>
              </React.Fragment>
            ))}
          </div>
        )}
      </Table>
    ))
  }

  return (
    <div className={cx('selected')}>
      <Table>
        <div className={cx('heading')}>
          <Heading2>{name}</Heading2>
          <Button
            className={cx('button')}
            onClick={() => showNewCompetitorModal(true)}
            type="primary"
          >
            Pridėti įrašą
          </Button>
          {!current.isLoading && current.notes.length === 0 && isAdmin &&
            <Button onClick={onDelete} className={cx('trash')} type="primary">
              Ištrinti
            </Button>}
        </div>
        <div className={cx('notes')}>
          {renderNotes()}
        </div>
      </Table>
    </div>
  );
};
