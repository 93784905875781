import {useSelector} from "react-redux";

export const useRole = () => {
  const {role} = useSelector((state)=>state.auth.user);
  return {
    isAdmin: role==='admin',
    isManager: role==='manager',
    isSales: role==='sales',
  }
}
