import React, {useState} from 'react';
import Table from "../../components/ui/Table/Table";
import InputPrimary from "../../components/ui/InputPrimary/InputPrimary";
import Button from "../../components/ui/Button/Button";
import {addCompetitor} from "../../store/competitors/competitors.actions";
import {useDispatch} from "react-redux";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const NewCompetitor = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const onSubmit = () => {
    if (value === '') return;
    dispatch(addCompetitor({name: value}))
    setValue('')
  }

  return (
    <div>
      <form className={cx('form')} onSubmit={(e) => e.preventDefault()}>
        <InputPrimary name='name'
                      className={cx('input')}
                      value={value}
                      label='Pavadinimas' type='text' onChange={(e) => setValue(e.target.value)}
        />
        <Button className={cx('button')}
                onClick={onSubmit}
                type='primary'>
          Įtraukti
        </Button>
      </form>
    </div>
  );
};
