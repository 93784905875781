import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import Icon from "../../../components/ui/Icon/Icon";
import {connect} from 'react-redux';
import * as actions from '../../../store/dashboard/reporForm/reportForm.actions';
import Dropzone from "react-dropzone";

const ReportFiles = (props) => {
  const {report} = props;

  if (report.type === 'meeting' || report.type === 'phone') return null;

  const files = report.files.map((file, i) => {
    return report.edit ?
      <div className={style.file} key={i}>
        <Icon
          icon='trash'
          size='sm'
          onClick={(e) => {
            e.stopPropagation();
            props.deleteFile(file, props.report._id)
          }}
          className={style.iconTrash}
        />
        {file}
      </div> :
      <a href={'/files/' + file} download key={i} className={style.file}>{file}</a>
  });

  return report.edit ?
    (
      <Dropzone onDrop={(files)=>props.updateFile(files, report)}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()} className={`${style.files} ${style.edit}`}>
            <input {...getInputProps()} />
            {files}
            <div className={style.btnUpload}>Įkelti dokumentus</div>
          </div>
        )}
      </Dropzone>
    )
    :
    (
      <div className={style.files}>
        {files}
      </div>
    )
};

ReportFiles.propTypes = {
  report: PropTypes.object.isRequired
};

export default connect(null, actions)(ReportFiles);
