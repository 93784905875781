import React, {useEffect, useState} from 'react';
import Heading1 from "../../components/ui/Heading1/Heading1";
import Loader from "../../components/ui/Loader/Loader";
import Table from "../../components/ui/Table/Table";
import {useDispatch, useSelector} from "react-redux";
import {addCompetitor, getCompetitors} from "../../store/competitors/competitors.actions";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {CompetitorData} from "./CompetitorData";
import {NewNote} from "./NewNote";
import {NewCompetitor} from "./NewCompetitor";

const cx = classNames.bind(styles);

export const Competitors = () => {
  const {competitors} = useSelector((state) => state.competitors);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState()
  const [newCompetitorModal, showNewCompetitorModal] = useState(false);

  useEffect(() => {
    dispatch(getCompetitors())
  }, []);

  const renderList = () => {

    if (competitors.isLoading) return <Loader loading={true}/>;

    return competitors.data.map((item, i) => {
      return (
        <Table onClick={() => setSelectedItem(item)} key={item.name}
             className={cx('item', item.id === selectedItem?.id && 'item--selected')}>
          <p>{item.name}</p>
        </Table>
      )
    });
  };
  return (
    <div className={cx('competitors')}>
      <Heading1>Konkurentai</Heading1>
      <Table>
        <NewCompetitor/>
        <div className={cx('list-table')}>
          <div className={cx('list')}>
            {renderList()}
          </div>
        </div>
      </Table>
      {selectedItem && <CompetitorData setSelectedItem={setSelectedItem} showNewCompetitorModal={showNewCompetitorModal} competitor={selectedItem}/>}
      <NewNote isVisible={newCompetitorModal} competitor={selectedItem}
               showNewCompetitorModal={showNewCompetitorModal}/>
    </div>
  );
}
