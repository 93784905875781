import thunk from 'redux-thunk';
import {combineReducers, createStore, compose, applyMiddleware} from 'redux';
import sideMenuReducer from './layout/sideMenu.reducer';
import settings from './settings';
import auth from './auth';
import notifications from './notifications';
import dashboard from './dashboard';
import companies from './companies';
import users from './users';
import rent from './rent/rent.reducer';
import reminders from './reminders/reminders.reducer';
import competitors from './competitors/competitors.reducer';

const rootReducer = combineReducers({
  sideMenu: sideMenuReducer,
  settings,
  auth,
  companies,
  users,
  notifications,
  dashboard,
  rent,
  reminders,
  competitors,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
