import {useEffect, useState} from 'react';

export const useDebounce = (
  value,
  callback,
  options,
) => {
  const delay = options ? options.delay : 500;
  const skipInitial = options ? options.skipInitial : true;
  const [hasLoaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, [hasLoaded]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (hasLoaded || !skipInitial) {
        callback();
      }
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value]);
};
